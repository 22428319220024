import React from "react"
import Footer from "../components/footer/footer"
import MusicList from "../components/music/music-list"
import ContactMe from "../components/contact/ContactMe"

import { graphql, useStaticQuery, StaticQuery } from "gatsby"

const fileQuery = graphql`
  {
    text: allFile(
      filter: { sourceInstanceName: { eq: "audio" }, extension: { eq: "md" } }
    ) {
      edges {
        node {
          id
          childMarkdownRemark {
            frontmatter {
              buttonUrl
              download
              year
              categories
              image {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
              file {
                publicURL
              }
              title
            }
            html
            excerpt
          }
        }
      }
    }
  }
`

const Music = () => {
  const { text } = useStaticQuery(fileQuery)

  return (
    <div id="wrapper" className="single-page-wrap">
      <MusicList text={text} />
      <div className="height-emulator fl-wrap"></div>
      <Footer />
      <ContactMe />
    </div>
  )
}

export default Music
