import React, { Suspense, lazy, useState, useEffect } from "react"

const ReactWaves = lazy(() => import("@dschoon/react-waves"), "default")
import Img from "gatsby-image"
import MouseTooltip from "react-sticky-mouse-tooltip"

const defaultState = {
  playing: false,
  audioContexts: [],
  test: true,
}

const MouseFollow = ({ isVisible }) => {
  return (
    <MouseTooltip visible={isVisible} offsetX={15} offsetY={10}>
      <div style={{ backgroundColor: "grey", margin: "5px", zIndex: "999999" }}>
        Click
      </div>
    </MouseTooltip>
  )
}

const Player = props => {
  const [state, setState] = useState(defaultState)
  const [currentPlayer, setCurrentPlayer] = useState({
    player: undefined,
    attached: false,
  })
  const [visible, setVisibility] = useState(false)

  const url = props.childMarkdownRemark.frontmatter.buttonUrl
    ? props.childMarkdownRemark.frontmatter.buttonUrl
    : props.childMarkdownRemark.frontmatter.download
    ? props.childMarkdownRemark.frontmatter.file.publicURL
    : null

  const handlePlay = () => {
    setState({ ...state, playing: !state.playing })
    if (currentPlayer.attached === false) {
      currentPlayer.volume = 1
      props.audioMotion.connectAudio(currentPlayer.player)
      setTimeout(() => {
        setCurrentPlayer({ player: currentPlayer.player, attached: true })
      }, 0)
    }
    props.handlePlayState(props.index)
  }

  const handleActiveToolTip = status => {
    setVisibility(status)
  }

  useEffect(() => {
    props.activePlayer !== props.index
      ? setState({ ...state, playing: false })
      : null
  }, [props.index, props.activePlayer])

  return (
    <div className="hidden-works-item fl-wrap">
      <MouseFollow isVisible={visible} />
      <div className="hidden-works-item-media">
        <div
          onMouseOver={() => {
            handleActiveToolTip(true)
          }}
          onMouseOut={() => {
            handleActiveToolTip(false)
          }}
          className="hidden-works-item-media-img fl-wrap"
          onClick={e => {
            handlePlay()
          }}
        >
          <Img
            fluid={
              props.childMarkdownRemark.frontmatter.image.childImageSharp.fluid
            }
            alt={props.childMarkdownRemark.frontmatter.title}
          />
          <a
            href="#"
            className="image-popup"
            title={`${state.playing ? "Stop" : "Play"} track`}
            onClick={e => {
              e.preventDefault()
            }}
          >
            <i className={`fal fa-${state.playing ? "stop" : "play"}`}></i>
          </a>
        </div>
        {typeof window !== `undefined` ? (
          <Suspense fallback={<div>No window present</div>}>
            <ReactWaves
              onClick={e => {
                e.preventDefault()
              }}
              onReady={e => {
                const thisAudioContext = e.wavesurfer.backend // .getAudioContext();
                var player = thisAudioContext.media
                player.id = "player" + props.index
                player.controlsList = "nodownload"
                player.crossOrigin = "anonymous"
                player.volume = 0.7
                player.controls = false
                setCurrentPlayer({ player: player, attached: false })

                setState({
                  ...state,
                  audioContexts: [
                    ...state.audioContexts,
                    thisAudioContext.getAudioContext(),
                  ],
                })
              }}
              audioFile={props.childMarkdownRemark.frontmatter.file.publicURL}
              className="waveform-wrapper"
              options={{
                audioContext: props.audioContext,
                backend: "MediaElement", // maps the waveform to an audio element
                mediaType: "audio",
                barHeight: 2,
                barWidth: 2,
                cursorWidth: 0,
                height: 80,
                hideScrollbar: true,
                progressColor: "#FAC921",
                responsive: true,
                waveColor: "#a9a9a9",
              }}
              volume={0}
              zoom={1}
              playing={state.playing}
            />
          </Suspense>
        ) : (
          ""
        )}

        <span>{`0${props.index + 1}`}.</span>
      </div>
      <div
        className="hidden-works-item-text"
        onClick={() => {
          handlePlay()
        }}
      >
        <h3
          title={`Click to ${state.playing ? "stop" : "start"}`}
          onMouseOver={() => {
            handleActiveToolTip(true)
          }}
          onMouseOut={() => {
            handleActiveToolTip(false)
          }}
        >
          {props.childMarkdownRemark.frontmatter.title}
        </h3>
        <ul>
          {props.childMarkdownRemark.frontmatter.categories.map(
            (category, idx) => {
              return <li key={idx}>{category}</li>
            }
          )}
        </ul>
      </div>
      <div className="audioinfo">
        <p
          dangerouslySetInnerHTML={{
            __html: props.childMarkdownRemark.html,
          }}
          className="audio-description"
        ></p>
        {url ? (
          <a href={url} className="btn btn-dark float-right" target="_blank">
            {props.childMarkdownRemark.frontmatter.download
              ? "Runter laden"
              : "Kaufen"}
          </a>
        ) : null}
      </div>
    </div>
  )
}

export default Player
