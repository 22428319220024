import React, { Component } from "react"
import AudioMotionAnalyzer from "audiomotion-analyzer" // creates analyzer
import Player from "./player"
import GetInTouch from "../get-in-touch/get-in-touch"
import { Link } from "gatsby"
import SEO from "../seo"
import { Dropdown } from "reactstrap"

class MusicList extends Component {
  initialSate = {
    activePlayer: null,
    players: [],
    selectedPreset: 0,
  }

  presets = [
    {
      name: "Standard",
      options: {
        mode: 5,
        fftSize: 8192,
        smoothing: 0.6,
        minFreq: 50,
        maxFreq: 16000,
        reflexFit: true,
        reflexAlpha: 1,
        reflexRatio: 0.4,
        reflexBright: 0.2,
        barSpace: 0.4,
        showPeaks: true,
        showScale: false,
      },
    },
    {
      name: "Radial",
      options: {
        fftSize: 1024,
        mode: 2,
        barSpace: 0.25,
        gradient: "classic",
        lumiBars: false,
        radial: true,
        spinSpeed: 0,
        reflexAlpha: 0.25,
        reflexBright: 1,
        reflexFit: true,
        barSpace: 0.5,
        showScale: false,
        showBgColor: false,
        showLeds: false,
        showPeaks: true,
        overlay: false,
      },
    },
    {
      name: "Reflex Bars",
      options: {
        mode: 5,
        barSpace: 0.25,
        gradient: "rainbow",
        lumiBars: false,
        radial: false,
        reflexAlpha: 0.25,
        reflexBright: 1,
        reflexFit: true,
        reflexRatio: 0.3,
        showBgColor: false,
        showLeds: false,
        showPeaks: true,
        overlay: false,
      },
    },
    {
      name: "Classic LEDs",
      options: {
        mode: 3,
        barSpace: 0.4,
        gradient: "classic",
        lumiBars: false,
        radial: false,
        reflexRatio: 0,
        showBgColor: true,
        showLeds: true,
        showPeaks: true,
      },
    },
    {
      name: "Mirror wave",
      options: {
        mode: 0,
        fillAlpha: 0.6,
        gradient: "prism",
        lineWidth: 2,
        fftSize: 4096,
        radial: false,
        reflexAlpha: 1,
        reflexBright: 1,
        reflexRatio: 0.5,
        showPeaks: false,
      },
    },
    {
      name: "Radial LEDs",
      options: {
        radial: true,
        fftSize: 4096,
        minFreq: 50,
        maxFreq: 16000,
        smoothing: 0.2,
        mode: 1,
        barSpace: 0.4,
        gradient: "prism",
        lumiBars: false,
        reflexRatio: 0,
        showBgColor: true,
        showLeds: true,
        showPeaks: true,
        spinSpeed: 1,
      },
    },
    {
      name: "Bar Jumper",
      options: {
        mode: 7,
        barSpace: 0.75,
        gradient: "rainbow",
        radial: false,
        minFreq: 50,
        maxFreq: 16000,
        showPeaks: false,
        showLeds: false,
      },
    },
    {
      name: "Mirror Line",
      options: {
        mode: 10,
        gradient: "rainbow",
        fftSize: 8192,
        minFreq: 100,
        maxFreq: 16000,
        smoothing: 0.7,
        barSpace: 0.4,
        reflexRatio: 0.5,
        reflexBright: 1,
        reflexAlpha: 1,
        reflexFit: true,
        lineWidth: 1,
        fillAlpha: 0,
        showPeaks: false,
      },
    },
  ]

  audioMotion = undefined
  audio = null
  currentPlayer = null
  audioContext = null

  constructor(props) {
    super(props)
    this.state = this.initialSate
    this.handlePlayState = this.handlePlayState.bind(this)
  }

  componentDidMount() {
    this.audioContext = new (window.AudioContext || window.webkitAudioContext)()

    var analyzerDiv = document.getElementById("analyzer")
    this.audioMotion = new AudioMotionAnalyzer(analyzerDiv, {
      ...this.presets[this.state.selectedPreset].options,
      audioCtx: this.audioContext,
    })

    var resumeAudio = function () {
      document.removeEventListener("click", resumeAudio)
    }

    document.addEventListener("click", () => {
      if (this.audioMotion.audioCtx.state === "suspended") {
        this.audioMotion.audioCtx.resume()
      }
      resumeAudio()
    })
  }

  setNewOptions = id => {
    this.audioMotion.setOptions(this.presets[id].options)
  }

  changeSetting = () => {
    var tooLong = this.state.selectedPreset === this.presets.length - 1
    if (tooLong) {
      this.setState({ selectedPreset: 0 })
    } else {
      this.setState({ selectedPreset: this.state.selectedPreset + 1 })
    }
    this.setNewOptions(tooLong ? 0 : this.state.selectedPreset + 1)
  }

  handlePlayState = idx => {
    this.setState({ activePlayer: idx })
  }

  render() {
    return (
      <div>
        <SEO title="Music" />
        <div id="wrapper" className="single-page-wrap">
          <div className="content">
            <div className="single-page-decor"></div>
            <div className="single-page-fixed-row">
              <div className="scroll-down-wrap">
                <div className="mousey">
                  <div className="scroller"></div>
                </div>
                <span>Runter scrollen</span>
              </div>
              <Link to="/" className="single-page-fixed-row-link">
                <i className="fal fa-arrow-left"></i> <span>Zum Start</span>
              </Link>
            </div>

            <section className="dark-bg no-padding">
              <div className="hidden-info-wrap-bg">
                <div
                  onClick={() => {
                    this.changeSetting()
                  }}
                  id="analyzer"
                  className="bg-ser"
                ></div>
                <button
                  onClick={() => {
                    this.changeSetting()
                  }}
                  className="btn btn-dark"
                  style={{
                    position: "relative",
                    zIndex: 22,
                    left: "-15px",
                    top: "400px",
                  }}
                >
                  Aussehen des Analyzers ändern <br />
                  {this.presets[this.state.selectedPreset].name}
                  <br />({this.state.selectedPreset + 1} /{" "}
                  {this.presets.length + 1})
                </button>
                <button
                  onClick={() => {
                    this.audioMotion.toggleFullscreen()
                  }}
                  className="btn btn-dark"
                  style={{
                    position: "relative",
                    zIndex: 22,
                    left: "-15px",
                    top: "400px",
                  }}
                >
                  Vollbildschirm
                </button>
              </div>

              <div className="hidden-info-wrap">
                <div className="hidden-info fl-wrap">
                  <div className="hidden-info-title">
                    Einige meiner Musikstücke
                  </div>

                  <div className="hidden-works-list fl-wrap">
                    {this.props.text.edges.map(({ node }, idx) => {
                      return (
                        <Player
                          audioContext={this.audioContext}
                          audioMotion={this.audioMotion}
                          key={idx}
                          index={idx}
                          activePlayer={this.state.activePlayer}
                          handlePlayState={this.handlePlayState}
                          {...node}
                        />
                      )
                    })}
                  </div>
                </div>
              </div>

              <div className="fl-wrap limit-box"></div>
            </section>

            <section className="dark-bg2 small-padding order-wrap">
              <GetInTouch />
            </section>
          </div>
        </div>
      </div>
    )
  }
}

export default MusicList
